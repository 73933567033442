<template>
  <div class="vx-snapshot vx-row -mx-2 flex-nowrap">
    <div class="vx-col px-2 flex-1 flex flex-col">
      <VxMagnifier
        v-if="!annotationEnabled"
        :src="screenshot.image.large"
        :src-large="screenshot.image.full"
      ></VxMagnifier>
      <VxImageAnnotation v-else :src="screenshot.image.large"></VxImageAnnotation>
    </div>
    <div class="vx-col px-2 w-16" v-if="$hasPermission(permissions.comments.add)">
      <vs-tooltip :text="annotationEnabled ? 'Exit comments' : 'Add comments'">
        <vs-avatar
          size="medium"
          :color="annotationEnabled ? 'success' : '#aaa'"
          icon-pack="feather"
          icon="icon-message-circle"
          @click="annotationEnabled = !annotationEnabled"
        />
        <span></span>
      </vs-tooltip>
    </div>
  </div>
</template>

<script>
import apiConfig from '@/config/api';
import VxMagnifier from '@/components/VxMagnifier';
import VxImageAnnotation from '@/components/VxImageAnnotation';

export default {
  props: {
    screenshot: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      annotationEnabled: false,
      permissions: apiConfig.permissions
    };
  },
  components: {
    VxMagnifier,
    VxImageAnnotation
  }
};
</script>
