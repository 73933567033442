<template>
  <section class="html-viewer-block">
    <vs-alert :active="prompt">HTML was not captured. Select "Capture HTML" when creating a capture.</vs-alert>
    <div v-if="!prompt">
      <vs-button
        color="primary"
        icon-pack="feather"
        icon="icon-download"
        class="mt-2 mb-2 ml-auto"
        @click="downloadHtml"
      >Download HTML</vs-button>
      <!--
      <editor
        :value="html"
        @init="editorInit"
        lang="html" theme="chrome" width="100%" height="500"></editor>
      -->
    </div>
  </section>
</template>

<script>
import FileSaver from 'file-saver';
export default {
  components: {
    //editor: require('vue2-ace-editor'),
  },
  props: {
    html: {
      type: String,
      required: true
    },
    prompt: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({}),
  methods: {
    downloadHtml() {
      const blob = new Blob([this.html], {type: "text/html;charset=utf-8"});
      FileSaver.saveAs(blob, `file.html`);
    },
    /*
    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/theme/chrome')
      require('brace/mode/html')
      //require('brace/snippets/javascript') snippet

      editor.setOption('readOnly', true);
    },*/
  }
}
</script>