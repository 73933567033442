<template>
  <section class="capture-inprogress flex bg-white">
    <div
      class="flex flex-col items-center justify-center m-auto w-full md:w-5/6 p-10 md:p-20"
    >
      <vs-icon icon-pack="feather" icon="icon-camera" size="large" />
      <h4 class="font-mono uppercase mb-base mt-3">Capturing</h4>
      <vs-progress :percent="progress" height="8" color="primary">primary</vs-progress>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    progress: 0,
    timer: null
  }),
  computed: {
    ...mapGetters('captures', ['hasScreenshots'])
  },
  methods: {
    Loader(time) {
      const $ctrl = this;
      return new Promise(resolve => {
        async function tick () {
          if ($ctrl.$route.name !== 'capture-view') {
            clearInterval($ctrl.timer);
            resolve(true);
            return;
          }
          const done = () => {
            $ctrl.$router.push(`/captures/${$ctrl.$route.params.id}`).catch(() => {
              $ctrl.$root.$main.$refs.view.reloadRouterView();
            });
            clearInterval($ctrl.timer);
            resolve(true);
          }
          $ctrl.progress = parseInt($ctrl.progress, 10) + 1;
          if ($ctrl.progress === 100) {
            done();
          } else if ($ctrl.hasScreenshots($ctrl.$route.params.id)) {
            await new Promise(res => {
              $ctrl.progress = 100;
              setTimeout(() => {
                res(true);
                done();
              }, 500);
            })
          }

          if ($ctrl.progress % parseInt(100/6) === 0) {
            await $ctrl.$store.dispatch('captures/item', { id: $ctrl.$route.params.id })
          }
        }
        $ctrl.timer = setInterval(tick, time / 120);
      });
    }
  },
  async beforeDestroy() {
    clearInterval(this.timer);
  },
  async mounted() {
    this.$nextTick(() => {
      const promise = Promise.resolve();
      promise.then(() => this.Loader(60 * 1000, 6));
    })
  }
};
</script>

<style lang="scss">
.capture-inprogress {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
