<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="capture-view-area data-list-container">
    <div class="capture-header mb-base">
      <div class="flex-grow">
        <p class="overflow-hidden mb-2 h-5 w-1/2 md:w-1/3">
          <ContentLoader></ContentLoader>
        </p>
        <p class="overflow-hidden h-5 w-1/3 md:w-1/5">
          <ContentLoader></ContentLoader>
        </p>
      </div>
      <div class="flex items-center">
        <div class="overflow-hidden h-8 md:h-10 w-16 md:w-24">
          <ContentLoader class="h-full w-full"></ContentLoader>
        </div>
        <div class="overflow-hidden ml-2 h-8 md:h-10 w-16 md:w-24">
          <ContentLoader class="h-full w-full"></ContentLoader>
        </div>
        <div class="overflow-hidden rounded-full ml-2 h-12 w-12">
          <ContentLoader class="h-full w-full"></ContentLoader>
        </div>
      </div>
    </div>

    <div class="capture-body">
      <div class="vx-row">
        <div class="vx-col capture-content w-full md:w-2/3">
          <div class="flex items-center mb-4">
            <div class="overflow-hidden h-8 md:h-10 w-16 md:w-1/5">
              <ContentLoader class="h-full w-full"></ContentLoader>
            </div>
            <div class="overflow-hidden ml-2 h-8 md:h-10 w-16 md:w-1/5">
              <ContentLoader class="h-full w-full"></ContentLoader>
            </div>
            <div class="overflow-hidden ml-2 h-8 md:h-10 w-16 md:w-1/5">
              <ContentLoader class="h-full w-full"></ContentLoader>
            </div>
            <div class="overflow-hidden ml-2 h-8 md:h-10 w-16 md:w-1/5">
              <ContentLoader class="h-full w-full"></ContentLoader>
            </div>
          </div>
          <div class="screenshots-block">
            <div class="vx-row -mx-2 flex-nowrap">
              <div class="vx-col px-2 w-16">
                <div class="overflow-hidden rounded-full mb-3 h-10 w-10">
                  <ContentLoader class="h-full w-full"></ContentLoader>
                </div>
                <div class="overflow-hidden rounded-full mb-3 h-10 w-10">
                  <ContentLoader class="h-full w-full"></ContentLoader>
                </div>
              </div>
              <div class="vx-col px-2 flex-1">
                <div class="overflow-hidden mb-3 h-64 w-full">
                  <ContentLoader class="h-full w-full"></ContentLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col capture-sidebar w-full md:w-1/3">
          <div class="capture-sidebar__header mb-6">
            <div class="flex mb-5">
              <p class="overflow-hidden h-5 w-1/2">
                <ContentLoader></ContentLoader>
              </p>
            </div>
            <p class="overflow-hidden h-6 w-full">
              <ContentLoader></ContentLoader>
            </p>
          </div>
          <hr />
          <div class="overflow-hidden mb-2 h-5 w-1/2">
            <ContentLoader class="h-full w-full"></ContentLoader>
          </div>
          <div class="overflow-hidden mb-2 h-4 w-full">
            <ContentLoader class="h-full w-full"></ContentLoader>
          </div>
          <hr />
          <div class="overflow-hidden mb-2 h-5 w-1/3">
            <ContentLoader class="h-full w-full"></ContentLoader>
          </div>
          <div class="overflow-hidden mb-2 h-5 md:h-10 w-full">
            <ContentLoader class="h-full w-full"></ContentLoader>
          </div>
          <hr />
          <div class="overflow-hidden h-8 md:h-10 w-16 md:w-1/3">
            <ContentLoader class="h-full w-full"></ContentLoader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
export default {
  components: {
    ContentLoader
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  mounted() {}
};
</script>
