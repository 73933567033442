<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <section>
    <Inprogress v-if="$route.query.inprogress" />
    <LoaderCaptureSingle v-if="loading && !$route.query.inprogress" />
    <div v-else class="capture-view-area data-list-container">
      <div class="capture-header mb-base">
        <div class="text-sm">
          <p class="flex items-center">
            <a :href="item.url | urlPath" target="_blank">
              <vs-icon icon-pack="feather" icon="icon-link" />
              {{ item.url | urlPath }}
            </a>
            <vs-button
              v-if="item.requestCookies || item.responseCookies"
              size="small"
              type="border"
              class="p-2 ml-3 tracking-wide"
              color="#333"
              @click="openCookiesTable"
            >
              Cookies</vs-button
            >
          </p>
          <p>
            <vs-icon icon-pack="feather" icon="icon-clock" />
            {{ $moment(item.dateCaptured).format('DD, MMM YYYY HH:mm') }}
          </p>
        </div>
        <div class="flex items-center">
          <vs-button type="flat" icon-pack="feather" icon="icon-search" class="mr-2" @click="viewComparison" size="small">Comparison</vs-button>
          <vs-button v-if="item.captureHtml" type="border" icon-pack="feather" icon="icon-rss" class="mr-2" @click="openLivePreview" size="small"
            >Live Preview</vs-button
          >
          <vs-button type="filled" color="success" icon-pack="feather" icon="icon-camera" class="mr-2" @click="capture" size="small"
            >Capture</vs-button
          >
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
            <vs-button radius icon="icon-more-vertical" icon-pack="feather" />

            <vs-dropdown-menu>
              <vs-dropdown-group vs-label="Mode" class="border border-t-0">
                <vs-dropdown-item
                  class="rounded overflow-hidden"
                  v-for="(mode, m) in modeOptions"
                  :key="m"
                  :class="{
                    'bg-primary text-white': selectedMode === mode.value
                  }"
                  @click="selectedMode = mode.value"
                >
                  <span class="flex items-center">
                    <feather-icon :icon="mode.icon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>{{ mode.label }}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item class="rounded overflow-hidden" @click="fullscreen">
                  <span class="flex items-center">
                    <feather-icon icon="MaximizeIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Fullscreen</span>
                  </span>
                </vs-dropdown-item>
                <vs-divider />
                <vs-dropdown-item
                  @click="setCaptureFavorite"
                  :class="{
                    'bg-primary text-white': item.favorite
                  }"
                >
                  <span class="flex items-center">
                    <feather-icon icon="StarIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Favorite</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="download">
                  <span class="flex items-center">
                    <feather-icon icon="DownloadIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Download</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-group>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <div class="capture-body">
        <div class="vx-row">
          <div class="vx-col capture-content w-full" :class="{ 'md:w-2/3': selectedMode !== 1 }">
            <vs-tabs vs-position="left" alignment="left" v-if="item.id" :key="item.key">
              <vs-tab v-for="(tab, i) in tabs" :key="i" :label="tab.label" icon-pack="feather" :icon="tab.icon" :disabled="tab.disabled">
                <component :is="tab.component" v-bind="tab.props" />
              </vs-tab>
            </vs-tabs>
          </div>
          <div class="vx-col capture-sidebar w-full text-sm" :class="{ 'md:w-1/3': selectedMode !== 1 }">
            <div class="capture-sidebar__header mb-6 cursor-pointer" @click="compareWithPrevious">
              <div class="flex mb-5">
                <p class="text-black"
                  ><strong>{{ item.detectedChangePercent }}%</strong><span class="text-xs"> Change detected</span></p
                >
              </div>
              <vs-progress height="20" :percent="item.detectedChangePercent" :color="changeColor(item.detectedChangePercent)"></vs-progress>
            </div>
            <!-- <p>1 <a href="#" class="text-warning" @click="$todo">capture</a> for this web page, viewing: <a href="#" class="text-warning" @click="$todo">1440x∞</a></p> -->
            <div v-if="(item.screenshots || []).length > 0">
              <hr />
              <label class="font-semibold mb-2 block">Embed link</label>
              <a :href="item.screenshots[0].image.full" target="_blank" class="inline-block text-xs truncate max-w-full">
                <vs-icon icon-pack="feather" icon="icon-link" class="mr-2 text-xs" />{{ item.screenshots[0].image.full }}
              </a>
            </div>
            <hr />
            <label class="font-semibold mb-2 block">Tags</label>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              @tags-changed="updateCaptureTags"
              @before-deleting-tag="updateCaptureTags"
              @adding-duplicate="duplicateTags"
              avoid-adding-duplicates
            />
            <hr />
            <a
              href="#"
              class="text-danger text-xs"
              @click="
                $vs.dialog({
                  type: 'confirm',
                  color: 'danger',
                  title: 'Confirm Delete',
                  text: `Are you sure? You're about to delete this capture.`,
                  accept: deleteCapture,
                  acceptText: 'Yes, delete it!'
                })
              "
            >
              <vs-icon icon-pack="feather" icon="icon-trash-2" class="mr-2 text-xs" />Delete Capture</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import LoaderCaptureSingle from '@/views/captures/components/loader/CaptureSingle';
import Screencasts from '@/views/captures/components/Screencasts';
import ImageSnapshot from '@/views/captures/components/ImageSnapshot';
import HtmlViewer from '@/views/captures/components/HtmlViewer';
import HarTabContent from '@/views/captures/components/HarTabContent';
import WarcTabContent from '@/views/captures/components/WarcTabContent';
import VueTagsInput from '@johmun/vue-tags-input';
import Inprogress from '@/views/captures/components/loader/Inprogress';
import api from '@/config/api';

export default {
  components: {
    LoaderCaptureSingle,
    VueTagsInput,
    Inprogress,
    ImageSnapshot,
    HtmlViewer
  },
  data() {
    return {
      tag: '',
      selectedMode: 0,
      modeOptions: [
        {
          value: 0,
          label: 'Normal',
          icon: 'MinimizeIcon'
        },
        {
          value: 1,
          label: 'Large',
          icon: 'Maximize2Icon'
        }
      ]
    };
  },
  computed: {
    ...mapState('captures', ['item', 'loading']),
    ...mapState({
      isSmallerScreen: state => state.windowWidth < 768
    }),
    tabs() {
      const item = this.item || {};
      const hasScreencasts = item.screencasts && item.screencasts.length > 0;
      return [
        ...(hasScreencasts
          ? [
              {
                label: 'Screencasts',
                icon: 'icon-video',
                component: Screencasts,
                props: { item }
              }
            ]
          : []),
        {
          label: 'Screenshots',
          icon: 'icon-image',
          component: ImageSnapshot,
          props: { item }
        },
        {
          label: 'HTML',
          icon: 'icon-tag',
          component: HtmlViewer,
          props: {
            html: item.html || '',
            prompt: item.captureHtml === false
          }
        },
        {
          label: 'Performance',
          icon: 'icon-trending-up',
          component: HarTabContent,
          props: { item }
        },
        {
          label: 'Web Archive',
          icon: 'icon-archive',
          component: WarcTabContent,
          disabled: !item.warcPath,
          props: {
            link: item.warcPath || ''
          }
        }
      ];
    },
    tags() {
      try {
        return this.item.tagItems.map(item => ({
          ...item.tag
        }));
      } catch (error) {
        return [];
      }
    }
  },
  methods: {
    changeColor(value) {
      if (value >= 25) {
        return 'danger';
      } else if (value >= 15) {
        return 'warning';
      } else if (value >= 0) {
        return 'success';
      } else {
        return 'primary';
      }
    },
    viewComparison() {
      this.$vxPopup.open({
        attrs: {
          title: 'Select Comparison'
        },
        config: {
          component: 'CalendarHeatMap',
          data: this.item
        }
      });
    },
    compareWithPrevious() {
      if (this.item.previousCapture && this.item.previousCapture) {
        this.$router.push(`/captures/${this.item.id}/compare/${this.item.previousCapture.id}`);
      }
    },
    fullscreen() {
      try {
        this.$vxPopup.open({
          attrs: {
            title: this.item.title,
            fullscreen: true
          },
          config: {
            component: 'BlockImage',
            src: (this.item.screenshots || [])[0].screenShotPath
          }
        });
      } catch (error) {
        this.$vs.notify({
          color: 'warning',
          title: 'Sorry',
          text: 'Image rendering interrupted, please try again later.'
        });
      }
    },
    download() {
      (this.item.screenshots || []).forEach(async item => {
        await this.$store.dispatch('downloadFile', item.screenShotPath);
      });
    },
    openLivePreview() {
      window.open(`${api.baseUrl}/preview/${this.item.id}`, '_new');
    },
    async updateCaptureTags(tags) {
      try {
        const isDelete = Boolean(tags.deleteTag);
        const data = isDelete ? tags.tag : tags.slice(-1)[0];
        const tag = await this.$store.dispatch('tags', {
          data,
          id: this.$route.params.id
        });
        this.$store.dispatch('captures/UPDATE_TAGS', isDelete ? data : tag);
      } catch (error) {
        console.log(error);
        return;
      }
    },
    duplicateTags() {
      this.$vs.notify({
        title: 'Error',
        text: `"${this.tag}" already exists`,
        color: 'danger',
        fixed: true
      });
    },
    async deleteCapture() {
      await this.$store.dispatch('captures/delete', {
        id: this.item.id,
        fromItemView: true
      });
      this.$router.push('/captures');
    },
    openCookiesTable() {
      this.$vxPopup.open({
        attrs: {
          title: 'Cookies'
        },
        config: {
          component: 'CookiesTable'
        }
      });
    },
    async capture() {
      const { $store, $router, item } = this;
      const result = await $store.dispatch('captures/capture', { id: item.id });
      if (result && result.id) {
        $router.push(`/captures/${result.id}?inprogress=true`);
        return result;
      }
      return false;
    },
    setCaptureFavorite() {
      this.$store.dispatch('captures/favorite', { id: this.item.id });
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {}
};
</script>
