<template>
  <div class="vx-image-annotation">
    <img ref="image" :src="src" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';

export default {
  props: {
    src: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('accounts', ['displayName', 'userId'])
  },
  data() {
    return {
      annotation: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.annotation = new Annotorious({ image: this.$refs.image });
      this.annotation.setAuthInfo({
        id: this.userId,
        displayName: this.displayName
      });
      console.log((window.$annotation = this.annotation));
    });
  }
};
</script>
