<template>
  <div class="capture-content-tab">
    <vs-button @click="open">Download Web Archive</vs-button>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    }
  },
  data: () => ({}),
  methods: {
    open() {
      window.open(this.link, '_blank');
    }
  }
}
</script>