<template>
  <div class="screenshots-block">
    <div class="vx-row -mx-2 flex-nowrap" v-if="(item.screenshots || []).length > 0">
      <div class="vx-col px-2 w-16">
        <vs-tooltip position="right" v-for="(size, i) in viewports || []" :key="i" :text="size">
          <vs-avatar
            size="medium"
            :color="size === currentScreenSize ? 'primary' : 'grey'"
            icon-pack="feather"
            :icon="getIconBySize(size)"
            @click="currentScreenSize = size"
          />
          <span></span>
        </vs-tooltip>
      </div>
      <div class="vx-col px-2 flex-1 flex flex-col">
        <div class="w-auto mb-5 flex" v-for="screenshot in screenshots" :key="screenshot.id">
          <Snapshot :screenshot="screenshot"></Snapshot>
        </div>
      </div>
    </div>
    <vs-alert v-else>No screenshot available</vs-alert>
  </div>
</template>

<script>
import Snapshot from './Snapshot';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedScreenShotId: null,
    currentScreenSize: null
  }),
  computed: {
    screenshots() {
      if (!this.currentScreenSize) return null;
      if (!(this.item.screenshots || []).length === 0) return null;
      const { screenshots } = this.item;
      const [selectedScreenWidth] = this.currentScreenSize.split('x');
      return screenshots
        .filter(item => {
          const viewport = `${item.viewPort.width}x${item.viewPort.height}`;
          return viewport.includes(`${selectedScreenWidth}x`);
        })
        .sort((a, b) => a.step - b.step);
    },
    viewports() {
      const viewports = new Array();
      const item = this.item;

      function concatScreenshots(screenshots) {
        for (let i = 0; i < screenshots.length; i++) {
          const item = screenshots[i];
          const viewPort = item.viewPort;
          const exists = viewports.find(s => s.includes(`${viewPort.width}x`));
          if (!exists) {
            viewports.push(`${viewPort.width}x${viewPort.height}`);
          } else {
            const [w, h] = exists.split('x');
            if (Number(h) !== viewPort.height) {
              const indexOfMatchingWidth = viewports.indexOf(exists);
              viewports.splice(indexOfMatchingWidth, 1, `${w}x`);
            }
          }
        }
      }

      if (item && item.screenshots && item.screenshots.length > 0) concatScreenshots(item.screenshots);
      return viewports;
    }
  },
  methods: {
    getIconBySize(size) {
      const [w] = size.split('x');
      return w > 768 ? 'icon-monitor' : 'icon-smartphone';
    }
  },
  mounted() {
    this.$nextTick(() => {
      const viewports = this.viewports;
      if (viewports && viewports.length > 0) this.currentScreenSize = viewports[0];
    });
  },
  components: {
    Snapshot
  }
};
</script>
