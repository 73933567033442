<template>
  <div class="capture-content-tab">
    <p class="flex mb-2">
      <span class="font-semibold flex mr-2">
        Document Loading Time
        <vs-tooltip class="flex items-center" text="Includes the time just to download the first HTML page in the test.">
          <feather-icon icon="InfoIcon" svgClasses="h-4 w-4" class="ml-1" />
          <span></span>
        </vs-tooltip>:
      </span>{{ loadingTime }}</p>
    <p class="flex mb-2">
      <span class="font-semibold flex mr-2">
        Total Capture Time
        <vs-tooltip class="flex items-center" text="Total time taken to capture all the pages and download all resources referenced by the page.">
          <feather-icon icon="InfoIcon" svgClasses="h-4 w-4" class="ml-1" />
          <span></span>
        </vs-tooltip>:
      </span> {{ captureTime }}</p>
    <vs-button @click="open" v-if="(item || {}).link">Download HAR</vs-button>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({}),
  computed: {
    loadingTime() {
      console.log(this.item)
      const { documentDownloadTimeMs } = (this.item || {});
      if (documentDownloadTimeMs) return `${documentDownloadTimeMs}ms`;
      return 'N/a';
    },
    captureTime() {
      const { totalCaptureTimeMs } = (this.item || {});
      if (totalCaptureTimeMs) return `${totalCaptureTimeMs}ms`;
      return 'N/a';
    }
  },
  methods: {
    open() {
      window.open(this.item.link, '_blank');
    }
  }
}
</script>